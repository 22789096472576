var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-7",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Deka Pop Usage Meter ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Search","outlined":"","dense":"","hide-details":"","clearable":""},on:{"input":_vm.applySearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"mb-5",attrs:{"items":_vm.filteredList,"headers":_vm.headersPop,"item-key":"id","hide-default-footer":"","server-items-length":_vm.totalRowsPop},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('popupquote',{attrs:{"offsetTop":20,"coordinate":_vm.coordinate,"documentid":'popup' + item.UsageMeterID,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                            on.click($event);
                            _vm.show($event, item);
                          }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                            rotate:
                              item.UsageMeterID == _vm.activemenuinstance && _vm.popo,
                          },attrs:{"id":'popup' + item.UsageMeterID,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(_vm.popo),callback:function ($$v) {_vm.popo=$$v},expression:"popo"}},[_c('v-list',{staticClass:"cursor-pointer font-weight-bold"},[_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"primary--text"},[_c('router-link',{attrs:{"to":("dekapop-list/detail/" + (item.UsageMeterID))}},[_vm._v("Details")])],1)])],1)],1)],1)]}},{key:"footer",fn:function(ref){
                          var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                        _vm.changePage(val);
                      }}})]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }