var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-7 py-7",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"headline font-weight-bold font--text"},[_vm._v(" Deka Pop Detail Usage Meter ")])]),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"px-2",attrs:{"align":"center","justify":"end"}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","readonly":"","dense":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"input":function($event){_vm.menuStart = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","readonly":"","dense":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"input":function($event){_vm.menuEnd = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),(_vm.role.toLowerCase() == 'superadmin')?_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"fz-14",attrs:{"block":"","color":"secondary","dark":"","height":"40"},on:{"click":_vm.fetchUsageData},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Loading...")])]},proxy:true}],null,false,2673048543)},[_vm._v(" Get Usage ")])],1):_vm._e(),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"fz-14",attrs:{"block":"","color":"primary","dark":"","height":"40"},on:{"click":_vm.downloadExcel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Export to Excel ")],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"mb-5",attrs:{"items":_vm.filteredList,"headers":_vm.headersPop,"item-key":"id","hide-default-footer":"","server-items-length":_vm.totalRowsPop},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('custom-footer-datatable',{attrs:{"props":props},on:{"input":function (val) {
                        _vm.changePage(val);
                      }}})]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }